//create a footer with 4 links one for Home, one for About, one for Projects, and one for Contact and copyright information

import React from 'react'
export const Footer = () => {
    const currentYear = new Date().getFullYear()
    return (
        <div className='footer'>
            <div className='footer-links'>
                <a className='footer-link' href='/'>Home</a>
                <a className='footer-link' href='/about'>About</a>
                <a className='footer-link' href='/projects'>Projects</a>
                <a className='footer-link' href='/contact'>Contact</a>
                <a className='footer-link' href='/terms'>Terms and Conditions</a>
                <a className='footer-link' href='/privacy'>Privacy Policy</a>
                <a className='footer-link' href='/delete'>Delete Account</a>
            </div>
            <div className='copy'>
                <p>© {currentYear} IOT2CLOUD. All Rights Reserved</p>
            </div>
        </div>
    )
}