import React from 'react'
import IOT2CloudLogo from '../Assets/IOT2CloudLogoPNG-bicubic.png'
import { useState } from 'react';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { 
  Box, 
  Drawer, 
  List,
  ListItem,
  ListItemButton, 
  ListItemIcon,  
  ListItemText,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import ContactMailIcon from '@mui/icons-material/ContactMail';



export const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const menuOptions = [
    {
      text: 'Home',
      icon: <HomeIcon />,
      link: '/',
    },
    {
      text: 'About',
      icon: <InfoIcon />,
      link: '/about',
    },
    {
      text: 'Projects',
      icon: <WorkIcon />,
      link: '/projects',
    },
    {
      text: 'Contact',
      icon: <ContactMailIcon />,
      link: '/contact',
    },
  ];



  return (
    <nav className='navbar'>
      <div className='logo'>
        <a href='/'>

          <img className='iot2cloud' src={IOT2CloudLogo} alt='logo'/>
        </a>
      </div>
      <div className='links'>
        <a className='link' href='/'>
          Home
        </a>
        <a className='link' href='/about'>
          About
        </a>
        <a className='link' href='/projects'>
          Projects
        </a>
        <a className='link' href='/contact'>
          Contact
        </a>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu}  onClose={() => setOpenMenu(false)}
        anchor='right'>
        <Box sx ={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}>
            <List>
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton component="a" href={item.link}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text}/>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
