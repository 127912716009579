import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/ContactPage';
import Projects from './Pages/Projects';
import Terms from './Pages/Terms';
import Delete from './Pages/Delete';
import Privacy from './Pages/Privacy';

function App() {
  let component 
  switch (window.location.pathname) {
    case "/":
      component = <Home />
      break;
    case "/about":
      component = <About />
      break;
    case "/contact":
      component = <Contact />
      break;
    case "/projects":
      component = <Projects />
      break;
    case "/terms":
      component = <Terms />
      break;
    case "/delete":
      component = <Delete />
      break;
    case "/privacy":
      component = <Privacy />
  }
  return (
    <div className="App">
      {component}
    </div>
  );
}

export default App;
