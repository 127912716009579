import React from 'react';
import Navbar from '../Components/Navbar';
import { Footer } from '../Components/footer';

export default function TermsPage() {
  const termsAndConditions = `
Welcome to IoT2Cloud! These Terms and Conditions govern your use of our software and services. By accessing or using our website, products, and services, you agree to abide by these Terms and Conditions. If you do not agree with these terms, please do not use our services..

1. Definitions a. "IoT2Cloud," "we," "us," or "our" refers to the software company IoT2Cloud and its affiliates. b. "User," "you," or "your" refers to any individual or entity accessing or using our services. c. "Services" encompass any software, applications, platforms, websites, and related services provided by IoT2Cloud.

2. License to Use Services a. IoT2Cloud grants you a limited, non-exclusive, non-transferable, and revocable license to use our services solely for your internal business or personal use, as permitted by these Terms and Conditions. b. You shall not modify, distribute, reproduce, create derivative works from, or reverse engineer any part of our services.  
        
3. User Accounts and Security a. In order to access certain features or services, you may need to create a user account. You are responsible for maintaining the confidentiality of your account credentials and any actions taken under your account. b. You agree to notify IoT2Cloud immediately of any unauthorized access or use of your account.

4. Privacy a. IoT2Cloud values your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our services, you consent to the terms of our Privacy Policy.

5. Intellectual Property Rights a. All intellectual property rights related to IoT2Cloud's services, including but not limited to software, trademarks, logos, and content, are owned or licensed by IoT2Cloud. b. You shall not use, reproduce, or distribute any of our intellectual property without prior written consent.

6. Acceptable Use a. You agree not to use our services for any unlawful, harmful, or abusive purposes. b. You shall not engage in any conduct that may impair or disrupt the functioning of our services or harm other users.

7. Third-Party Services and Links a. IoT2Cloud's services may include links to third-party websites or services. We do not endorse or assume responsibility for the content or actions of any third-party service. b. Your use of any third-party service is at your own risk, and you should review their respective terms and conditions.

8. Disclaimer of Warranties a. Our services are provided on an "as is" and "as available" basis, without any warranties or representations, express or implied. b. IoT2Cloud does not warrant that our services will be error-free, uninterrupted, or free from viruses or other harmful components.

9. Limitation of Liability a. In no event shall IoT2Cloud be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use our services. b. IoT2Cloud's total liability shall not exceed the amount paid by you, if any, for accessing our services.

10. Termination a. IoT2Cloud may terminate or suspend your access to our services at any time and for any reason. b. Upon termination, you must cease all use of our services, and any provisions that would reasonably be expected to survive termination shall remain in effect.

11. Modifications to Terms and Services a. IoT2Cloud may update these Terms and Conditions from time to time. Any material changes will be communicated through our website or other means. b. Your continued use of our services after such changes will constitute acceptance of the updated Terms and Conditions.

12. Governing Law a. These Terms and Conditions shall be governed by and construed in accordance with the laws of British Columbia, Canada. b. Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in British Columbia, Canada.

Please read these Terms and Conditions carefully before using our services. If you have any questions or concerns, feel free to contact us at Customersupport@iot2cloud.com. Thank you for choosing IoT2Cloud!
`;

  return (
    <>
     <style>
        {`
          /* Apply padding to the terms and conditions text */
          .terms-textbox {
            padding: 10px;
          }
        `}
      </style>
      <Navbar />
      <div className='terms'>
        <div className='terms-info'>
          <h1 className='terms-banner-title'><span>Terms and Conditions</span></h1>
          <div className='terms-text'>
           <div className='terms-textbox-container'>
            <textarea
              className='terms-textbox'
              rows='30'
              readOnly
              value={termsAndConditions}
            />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
