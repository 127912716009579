import React from 'react'
import Navbar  from '../Components/Navbar';
import { Footer } from '../Components/footer';
import { Contact } from '../Components/contact';
import WoodHandImg from '../Assets/Wood-Hand.png'
import Supervisor from '../Assets/supervisor_account.png'
import DB from '../Assets/database.png'
import Tune from '../Assets/tune.png'
import TableChart from '../Assets/table_chart.png'
import OTG from '../Assets/OTG.png'
import Rectangle from '../Assets/Rectangle.png'
import iPhone13 from '../Assets/iPhone13.png'



const Home = () => {
  return (
   <div className='home'>
        <div className='home-container'>
            <Navbar />
            <div className='home-banner-container'>
                <div className='home-banner'>
                    <h1 className='home-banner-title'>Pure Innovation</h1>
                    <p className='home-banner-text'>Welcome to IoT2Cloud, our aim is to provide  cutting-edge IoT device management solutions. Our platform is designed to help businesses of all sizes connect, maintain, and interact with their IoT devices like never before. </p>
                    <button className='home-banner-button'>LEARN MORE</button>
                    <div class='home-banner-image'>
                        <img src={WoodHandImg} />
                    </div>
                </div>      
            </div>
        </div>
        <div class="home-features">
            <h2 class="home-features-banner-title">Unique Features</h2>
            <div class="home-features-container"> 
                <div class="home-feature">
                    <div class="home-feature-icon">
                        <img src={Supervisor}/>
                    </div>
                    <h3 class="home-feature-title">Customizable App Components</h3>
                    <p class="home-feature-text">Fully customize the app to create a unique user experience that reflects your brand's identity.</p>
                </div>
                <div class="home-feature">
                    <div class="home-feature-icon">
                        <img src={DB}/>
                    </div>
                    <h3 class="home-feature-title">Device Data Structure</h3>
                    <p class="home-feature-text">Easily interact with IoT device data in real-time and optimize performance with our android & IOS data base and API.</p>
                </div>
                <div class="home-feature">
                    <div class="home-feature-icon">
                        <img src={Tune}/>
                    </div>
                    <h3 class="home-feature-title">Commercially Compliant</h3>
                    <p class="home-feature-text">Fully compliant with commercial regulations for the highest standards of security and compliance.</p>
                </div>
                <div class="home-feature">
                    <div class="home-feature-icon">
                        <img src={TableChart}/>
                    </div>
                    <h3 class="home-feature-title">Seamless Integration & Scalability</h3>
                    <p class="home-feature-text">Seamlessly integrate with existing systems, applications and easily add or remove devices as needed.</p>
                </div>
            </div>
        </div>
        <div class="home-partners">
            <h2 class="home-partners-banner-title"><span>Our Partner</span></h2>
                <img src={OTG} />
        </div>
        <div class="home-get-started" style={{ background: `url(${Rectangle}) no-repeat center center`, backgroundSize: '80em' }}>
            <div class="home-get-started-content">
                <h2 class="home-get-started-banner-title"><span>Get Started Now</span></h2>
                <p class="home-get-started-banner-text">
                    With our state-of-the-art technology, you'll have complete control over your IoT ecosystem, 
                    enabling you to make informed decisions, optimize performance, and maximize ROI. 
                    Discover how IoT2Cloud  can transform the way you do business today.
                </p>
                <button class="home-get-started-button">CONTACT US</button>
            </div>
            <img class="home-get-started-iphone" src={iPhone13} />
        </div>
        <Contact />
        <Footer />
    </div>

  )
}

export default Home