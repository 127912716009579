// create an employee card component with a picture, name, and title
// and then use it in the About.js file


import React from 'react';
const Card = ({img, name, title}) => {
    return (
    <div className='our-team-card'>
      <div className='our-team-card-content'>
        <div className='our-team-card-img-container'>
             <img className='our-team-card-img' src={img} alt={name} />
        </div>
        <div className='our-team-card-details'>
          <p className='our-team-card-name'>{name}</p>
          <p className='our-team-card-title'>{title}</p>
        </div>
      </div>
    </div>
    );
}

export default Card;




