import React from 'react';
import Navbar from '../Components/Navbar';
import { Footer } from '../Components/footer';

export default function PrivacyPage() {
  const privacyPolicy = `
    <div style="max-height: 80vh; overflow-y: scroll; padding-left: 10px;">
      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Safeguarding Your Information</span></p>

      <p style="margin-bottom: 10px;">At IOT2CLOUD, we are dedicated to maintaining the trust and confidence of our users. Our commitment to your privacy is fundamental, and this Privacy Policy is designed to provide transparency about the information we collect, how it's used, and how you can control your data.</p>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Information We Collect:</span></p>
      <p style="margin-bottom: 10px;">We collect information to provide you with a seamless and personalized experience while using our services. This includes:</p>
      <ul>
        <li>Personal Information: When you register for an account or interact with our services, we may collect personal data such as your name, email address, and contact details.</li>
        <li>Usage Information: We gather information about how you use our services, including your interactions, preferences, and activities.</li>
        <li>Device Information: We may collect device-specific information, such as your hardware model, operating system, and unique device identifiers.</li>
      </ul>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">How We Use Your Information:</span></p>
      <p style="margin-bottom: 10px;">Your information is used to enhance your experience, provide customer support, and improve our services. Specifically:</p>
      <ul>
        <li>Personalization: We use your information to tailor your experience and provide you with relevant content and recommendations.</li>
        <li>Communication: We may send you updates, newsletters, or service-related announcements. You can always opt-out of these communications.</li>
        <li>Analytics: We use data to analyze trends, monitor usage patterns, and improve the performance and functionality of our services.</li>
      </ul>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Data Protection and Security:</span></p>
      <p style="margin-bottom: 10px;">We are committed to safeguarding your data and employ industry-standard security measures to prevent unauthorized access, disclosure, or misuse.</p>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Your Choices:</span></p>
      <ul>
        <li>Access and Control: You can review, update, or delete your information through your account settings or by contacting our support team.</li>
        <li>Cookies: You can control cookies through your browser settings and preferences. However, please note that disabling cookies may affect your experience on our site.</li>
      </ul>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Third-Party Sharing:</span></p>
      <p style="margin-bottom: 10px;">We do not share your personal information with third parties without your explicit consent, except for the purposes of providing our services and as required by law.</p>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Children's Privacy:</span></p>
      <p style="margin-bottom: 10px;">Our services are not intended for children under the age of 13. We do not knowingly collect or store personal information from children.</p>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Changes to This Policy:</span></p>
      <p style="margin-bottom: 10px;">We may update our Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of any substantial changes and provide the updated policy on our website.</p>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Contact Us:</span></p>
      <p style="margin-bottom: 10px;">If you have any questions or concerns about our Privacy Policy or your data, please contact our Data Protection Officer at <a href="mailto:Customersupport@iot2cloud.com">Customersupport@iot2cloud.com</a>.</p>

      <p style="margin-bottom: 10px;">By using our services, you agree to the terms outlined in this Privacy Policy. We value your trust and are committed to protecting your privacy as we work together to create a safe and enriching online experience.</p>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Sincerely,</span></p>
      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">IOT2CLOUD</span></p>
      
    </div>
  `;

  return (
    <>
      <style>
        {`
          /* Target the scroll bar */
          div::-webkit-scrollbar {
            width: 8px; /* Adjust the width as needed */
          }

          /* Track */
          div::-webkit-scrollbar-track {
            background: #white; /* You can change the color */
          }

          /* Handle */
          div::-webkit-scrollbar-thumb {
            background: #D9D9D9; /* You can change the color */
            border-radius: 8px;
          }
        `}
      </style>
      <Navbar />
      <div className='terms'>
        <div className='terms-info'>
          <h1 className='terms-banner-title'><span>Privacy Page</span></h1>
          <div className='terms-text'>
            <div
              className='terms-textbox'
              dangerouslySetInnerHTML={{ __html: privacyPolicy }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
