import React from 'react'
import Navbar  from '../Components/Navbar';
import { Footer } from '../Components/footer';
import LogoOTG from '../Assets/LogoOTG.png';
import OTGPhone from '../Assets/otg-phone.png';
import LogoErgonomyx from '../Assets/ergonomyx_logo_Registered.png';
import ErgonomyxBike from '../Assets/NewBike_Transparent.png';
import LogoInventu from '../Assets/LogoInventu.png';
import LogoGrace from '../Assets/LogoGrace.png';
import Unsplash from '../Assets/Unsplash.png';
import iPhone13Pro from '../Assets/iPhone13Pro.png';
import download_done from '../Assets/download_done.png';

export default function Projects() {
    return (
        <>
            <Navbar />
                <div className='partnerships-container'>
                    <div className='partnerships'>
                        <h1 className='partnerships-banner-title'><span>Partnerships</span></h1>
                    </div>
                    <div className='iot-partnerships-banner-text-container'>
                        <h1 className='iot-partnerships-banner-header'>IOT integration</h1>
                        <div className='iot-partnerships-banner-first-text-container'>
                            <p className='iot-partnerships-banner-text-left'>We're excited to have partnered with Off The Grid, an eco-friendly fitness company that's dedicated to making a positive impact on the planet. Off The Grid offers a range of eco-friendly training equipment. </p>
                            <img className='iot-partnerships-banner-img-right' src={LogoOTG} />
                        </div>
                        <div className='iot-partnerships-banner-second-text-container'>
                            <img className='iot-partnerships-banner-img-left' src={OTGPhone} />
                            <p className='iot-partnerships-banner-text-right'>With IoT2Cloud's comprehensive platform, Off The Grid has been able to streamline their operations, gain valuable insights into their customers' fitness journeys, and gamify the exercise experience.</p>
                        </div>
                    </div>
                    <div className='grace-partnerships-banner-text-container'>
                        <h1 className='ergonomyx-partnerships-banner-header'>Voice Recognition</h1>
                        <div className='iot-partnerships-banner-first-text-container'>
                            <p className='iot-partnerships-banner-text-left'>Grace App is a voice recognition app that is focused on community-led prayers. The app allows users to record their prayers, which can be shared with others to pray together asynchronously. With Grace App, users can experience the power of communal prayer without being in the same physical location.  </p>
                            <img className='inventu-partnerships-banner-img-right' src={LogoGrace} />
                        </div>
                    </div>  
                    <div className = 'bridging-the-gap-container'>
                       <div className='bridging-the-gap-container-left'>
                            <h1 className='bridging-the-gap-header'>Bridging The Gap</h1>
                            <h3 className='bridging-the-gap-subheader'>Between your Smart Device & Data</h3>
                            <div className='bridging-the-gap-text-container'>
                                <p className='bridging-the-gap-first-paragraph-text'>Dedicated to helping businesses thrive in the IoT space. Our mission is to provide our clients with the tools and expertise they need to succeed in today's digital age. With our cutting-edge platform and unparalleled support. 
                                <br /> <br /> We're committed to helping businesses harness the full potential of IoT technology and achieve their goals. we're unlocking the full potential of IoT technology and helping businesses thrive in the digital age.</p>
                            </div>
                        </div>
                        <div className='bridging-the-gap-container-right'>
                            <img className='bridging-the-gap-img' src={Unsplash} />
                        </div>
                    </div> 

                    <div className='iot2cloud-features-banner-container'>
                        <h1 className='iot2cloud-features-banner-title'>Features</h1>
                        <div className='iot2cloud-features-banner'>
                            <div className='iot2cloud-features-banner-left'>
                                <p className='iot2cloud-features-banner-text'> <img className='check-img' src={download_done} alt='icon'/> Improve efficiency and reduce costs. </p>
                                <p className='iot2cloud-features-banner-text'> <img className='check-img' src={download_done} alt='icon'/> User-friendly interface and engaging features. </p>
                                <p className='iot2cloud-features-banner-text'> <img className='check-img' src={download_done} alt='icon'/> Improve profitability by optimizing device performance & gaining insights. </p>
                                <p className='iot2cloud-features-banner-text-no-line'> <img className='check-img' src={download_done} alt='icon'/> Spend less time & resources on software development </p>
                            </div>
                            <div className='iot2cloud-features-banner-middle'>
                                <img className='iot2cloud-features-banner-img' src={iPhone13Pro} />
                            </div>
                            <div className='iot2cloud-features-banner-right'>
                                <h4 className='iot2cloud-features-banner-right-header'>IoT Device Management: </h4>
                                <p className='iot2cloud-features-banner-text-black'>Optimize performance and monitor IoT devices fast and efficiently. </p>
                                
                                <h4 className='iot2cloud-features-banner-right-header'>Customizable Front-End Framework: </h4>
                                <p className='iot2cloud-features-banner-text-black'>Create a seamless user experience that meets your unique needs </p>
                               
                                <h4 className='iot2cloud-features-banner-right-header'>Commercially Viable API: </h4>
                                <p className='iot2cloud-features-banner-text-black'>Secure and scalable solution for integrating IoT devices. </p>
                            </div>
                        </div>       
                     </div>
            </div>
            <Footer />

        </>
    );
}