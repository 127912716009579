import React from 'react';
import Navbar from '../Components/Navbar';
import { Footer } from '../Components/footer';
import mobile1 from '../Assets/mobile-1.png';
import mobile2 from '../Assets/mobile-2.png';
import mobile3 from '../Assets/mobile-3.png';
import mobile4 from '../Assets/mobile-4.png';

export default function DeletePage() {
    const deleteAccountContent = `
    <div style="max-height: 80vh; overflow-y: scroll; padding-left: 10px;">
      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Please be aware of the following important points before proceeding:</span></p>

      <p style="margin-bottom: 10px;">1. Tracking Information: Upon initiating the account deletion process, your tracking information will no longer be collected, and your activity won't be used for any future analysis or improvements.</p>
      <p style="margin-bottom: 10px;">2. Irreversible Action: Deleting your account is permanent and irreversible. All data associated with your account, including personal details, preferences, and historical interactions, will be permanently removed from our systems. This action cannot be undone.</p>

      <p style="margin-bottom: 10px;"><span style="font-weight: 600;">Account Deletion Steps:</span></p>
      <p style="margin-bottom: 10px;">1. Log In: Sign in to your account using your credentials.</p>
      <p style="margin-bottom: 10px;">2. Access Settings: Navigate to the "Settings" section, usually located on your side menu.</p>
      <p style="margin-bottom: 10px;">3. Initiate Deletion: Within the "Settings," select the “preferences” option. At the bottom of the page, you will find the "Delete Account" button.</p>
      <p style="margin-bottom: 10px;">4. Authentication: As an additional security measure, you will be required to provide your current password to complete deleting your account.</p> 
      
      <br>
      <p style="margin-bottom: 10px;">We genuinely appreciate your trust in us and respect your decision. Remember that once your account is deleted, your access to our services and any associated data will cease to exist. If you have any concerns or questions during this process, our customer support team is here to assist you.</p>
      
      <p style="margin-bottom: 10px;">Thank you for being a part of our community, and we wish you all the best in your future endeavors.</p>
      
      <div style="display: flex; justify-content: space-between; flex-wrap: wrap; padding: 0 20px;">
        <img src="${mobile1}" alt="Mobile 1" width="160" style="margin: 5px;" />
        <img src="${mobile2}" alt="Mobile 2" width="160" style="margin: 5px;" />
        <img src="${mobile3}" alt="Mobile 3" width="160" style="margin: 5px;" />
        <img src="${mobile4}" alt="Mobile 4" width="160" style="margin: 5px;" />
      </div>
    </div>
  `;

  return (
    <>
      <style>
        {`
          /* Target the scroll bar */
          div::-webkit-scrollbar {
            width: 8px; /* Adjust the width as needed */
          }

          /* Track */
          div::-webkit-scrollbar-track {
            background: #white; /* You can change the color */
          }

          /* Handle */
          div::-webkit-scrollbar-thumb {
            background: #D9D9D9; /* You can change the color */
            border-radius: 8px;
          }
        `}
      </style>
      <Navbar />
      <div className='terms'>
        <div className='terms-info'>
          <h1 className='terms-banner-title'><span>Delete Account Page</span></h1>
          <div className='terms-text'>
            <div
              className='terms-textbox'
              dangerouslySetInnerHTML={{ __html: deleteAccountContent }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

