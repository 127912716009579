import React from 'react';

export const Contact = () => {
  function handleFormSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = 'devteam@iot2.ca';
    const subject = 'Contact Form Submission';
    const body = serializeFormData(formData);

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  }

  function serializeFormData(formData) {
    const entries = Array.from(formData.entries());
    const formattedEntries = entries.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    return formattedEntries.join('&');
  }

  return (
    <div className='contact'>
      <div className='contact-info'>
        <div className='contact-info-text'>
          <p className='contact-info-text-title'>375 Quayle road, Victoria, BC V9E2B4</p>
          <p className='contact-info-text-title'>customersupport@iot2.ca</p>
        </div>

        <div className='contact-info-form'>
          <form className='contact-form' onSubmit={handleFormSubmit}>
            <h1 className='contact-h1'>Contact us</h1>
            <div className='contact-row'>
              <input type='text' placeholder='First Name' className='bottom-line' name='firstName' />
              <input type='text' placeholder='Last Name' className='bottom-line' name='lastName' />
            </div>
            <div className='contact-row'>
              <input type='text' placeholder='Email' className='bottom-line' name='email' />
              <input type='text' placeholder='Phone Number' className='bottom-line' name='phoneNumber' />
            </div>
            <div className='contact-row'>
              <textarea placeholder='Message' className='border-line' name='message' />
            </div>
            <div className='contact-row'>
              <button className='contact-submit' type='submit'>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
