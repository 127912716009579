import React from 'react'
import Navbar  from '../Components/Navbar';
import { Footer } from '../Components/footer';
import { Contact } from '../Components/contact';
import location from '../Assets/location.png'

export default function ContactPage() {
    return (
        <>
         <Navbar />
            <div className='contact-container'>
                <Contact />
            </div>
            <div className='location-container'>
                <div className='location'>
                    <div className='location-info'>
                        <h1 className='location-banner-title'><span>Find Us</span></h1>
                        <div className='location-image'>
                            <img src={location} />
                        </div>
                    </div>
                </div>
          </div>
        <Footer />
        </>    
    );
}