import React from 'react'
import Navbar  from '../Components/Navbar';
import { Footer } from '../Components/footer';
import  Card  from '../Components/card';
import Analytics from '../Assets/analytics.png'
import Aleah from '../Assets/Aleah.jpg'
import Alireza from '../Assets/Alireza.jpeg'
import Damian from '../Assets/Damian.jpg'
import Fabian from '../Assets/Fabian.jpg'
import Sergio from '../Assets/Sergio.jpg'
import Shubham from '../Assets/Shubham.jpg' 
import Reece from '../Assets/Reece.jpg'
import Elaine from '../Assets/Elaine.jpg'
import Ana from '../Assets/Ana.jpg'


export default function About() {
    return (
       <div>
            <div className='about-container' style={{ background: `url(${Analytics}) no-repeat center center`, backgroundSize: '100%' }}   >
                <Navbar />
            </div>
            <div className = 'our-story-container'>
                <div className='our-story'>
                    <h1 className='our-story-banner-title'><span>Our Story</span></h1>
                    <div className='our-story-banner-text-container'>
                        <p className='our-story-banner-text '>At IOT2Cloud we're a team of passionate professionals who are dedicated to helping businesses succeed in the fast-growing IoT space. we saw the potential of IoT technology to transform the way businesses operate and realized that many companies were struggling to structure their IoT devices effectively.  </p>
                        <p className='our-story-banner-text '>With our deep understanding of IoT technology and our commitment to providing top-notch solutions, we set out to develop a comprehensive platform that could help businesses of all sizes unlock the full potential of their IoT ecosystems. Today, we're proud to be a trusted partner in the IoT device space. Our journey has just begun, and we're excited to continue innovating and delivering best-in-class solutions to our clients.</p>
                    </div>
                </div>
            </div>   
            <div className = 'our-purpose-container'>
                <div className='our-purpose'>
                    <h1 className='our-purpose-banner-title'><span>Our Purpose</span></h1>
                    <div className='our-purpose-banner-text-container'>
                        <p className='our-purpose-banner-text'>"We believe that IoT technology has the power to transform the way businesses operate. We're dedicated to helping companies of all sizes unlock the full potential of their IoT ecosystems. Our goal is to provide the best-in-class solutions to our clients, and we're committed to delivering the highest quality of service." </p>
                    </div>
                </div>
            </div>
            <div className = 'our-team-container'>
                <div className='our-team'>
                    <h1 className='our-team-banner-title'><span>Our Team</span></h1>
                    <div className='card-grid'>
                        <Card img={Fabian} name='Fabian De La Fuente' title='Advisor' />
                        <Card img={Alireza} name='Alireza Moazeni' title='Technology Lead' />
                        <Card img={Reece} name='Reece Pretorius' title='Full Stack Mobile Developer' />
                        <Card img={Shubham} name='Shubham Negi' title='Full Stack Developer' />
                        <Card img={Damian} name='Damian Oriak' title='Creative Leader' />
                        <Card img={Ana} name='Ana Lawrence' title='Digital Media Lead' />
                        <Card img={Aleah} name='Aleah Grymaloski' title='Full Stack Mobile Developer' />
                    </div>
                </div>
            </div>

            <Footer />


       </div>     
    );
}
